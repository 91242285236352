import * as React from "react";
import { Typography, Box, Stack, Grid } from "@mui/material";

// components
import Page from "../utils/page";
import Link from "../utils/link";
import Section from "../utils/section";
import SocialLinks from "../utils/social-links";
import Address from "./address";

// markup
const Contatti = () => {
    return (
        <Section>
            <Grid container spacing={5} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <iframe
                        title="Mappa Farmacia Settimo Miglio"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.3793134741!2d10.900771515558011!3d45.482305879101084!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4781e38c02f4c23b%3A0xa3d31e15a10017a0!2sFarmacia%20Settimo%20Miglio%20della%20Dott.ssa%20Caobelli%20Annamaria!5e0!3m2!1sit!2sit!4v1638987036012!5m2!1sit!2sit"
                        width="100%"
                        height="450"
                        style={{ border: 0, borderRadius: 16 }}
                        loading="lazy"
                    ></iframe>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Address />
                </Grid>
            </Grid>
        </Section>
    );
};

export default Contatti;
