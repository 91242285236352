import * as React from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Link from "../utils/link";

const AreeCard = () => {
    return (
        <Link type="internal" href="/aree" underline="none" title="Scopri le nostre aree">
            <Box>
                <StaticImage imgStyle={{ borderRadius: 16 }} src="../images/le-nostre-aree.jpg" alt="Le nostre aree" layout="fullWidth" />

                <Box py={4}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="h3">
                            <strong>Le nostre Aree</strong>
                        </Typography>
                        <Typography>Il nostro obiettivo è offrire le soluzioni migliori personalizzando il consiglio.</Typography>
                        <Box>
                            <Button style={{ padding: 8, marginLeft: -8 }} size="large" variant="text" color="info" endIcon={<ArrowForwardIcon />}>
                                Scopri di più
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Link>
    );
};

export default AreeCard;
