import * as React from "react";
import { Typography, Box, Stack, Button } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Link from "../utils/link";

// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ServiziCard = () => {
    return (
        <Link type="internal" href="/aree" underline="none" title="Scopri i nostri servizi">
            <Box>
                <StaticImage imgStyle={{ borderRadius: 16 }} src="../images/i-nostri-servizi.jpg" alt="Le nostre aree" layout="fullWidth" />

                <Box py={4}>
                    <Stack spacing={2}>
                        <Typography variant="h3" component="h3">
                            <strong>I nostri Servizi</strong>
                        </Typography>
                        <Typography>Offriamo una vasta gamma di servizi per la salute della persona.</Typography>
                        <Box>
                            <Button style={{ padding: 8, marginLeft: -8 }} size="large" variant="text" color="info" endIcon={<ArrowForwardIcon />}>
                                Scopri di più
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </Link>
    );
};

export default ServiziCard;
