import * as React from "react";
import { Grid, Box } from "@mui/material";

// component
import AreeCard from "./aree-card";
import ServiziCard from "./servizi-card";
import Section from "../utils/section";

const AreeServiziCard = () => {
    return (
        <Section>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <AreeCard />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ServiziCard />
                </Grid>
            </Grid>
        </Section>
    );
};

export default AreeServiziCard;
