import * as React from "react";
import { Container, Box, Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

// icons
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// components
import Page from "../../utils/page";
import Link from "../../utils/link";
import Hero from "../../utils/hero";
import Section from "../../utils/section";
import AreeServiziCard from "../../components/aree-e-servizi";
import theme from "../../utils/theme";
import Contatti from "../../components/contatti";

// markup
const HolterCardiaco = () => {
    return (
        <Page
            title="Esame Holter cardiaco direttamente in Farmacia"
            description="Eseguire l'esame holter cardiaco direttamente in farmacia permette al paziente un accesso rapido e immediato con minimi tempi di attessa. L'esame ha la medesima validità dello stesso svolto presso altra struttura sanitaria."
        >
            <Hero
                color="#fff"
                title="Holter cardiaco"
                description={
                    <>
                        Offriamo la possibilità di eseguire un esame con Holter Cardiaco 24-48h direttamente in
                        farmacia. Le prenotazioni hanno tempi di attesa molto brevi.
                        <Box mt={2}>
                            <Typography>Costo holter cardiaco:</Typography>
                            <Typography variant="h5">70€ detraibili fiscalmente</Typography>
                        </Box>
                    </>
                }
                background={theme.palette.primary.main}
                backgroundImage={
                    <StaticImage
                        style={{ height: "100%" }}
                        quality={100}
                        src="../../images/farmacia-settimo-miglio-cosmesi.jpg"
                        alt="Farmacia Settimo Miglio tampone Covid-19"
                        layout="fullWidth"
                    />
                }
            />

            <Section>
                <Grid container spacing={5}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom component="h3" color="primary">
                            L'esame
                        </Typography>
                        <Typography>
                            L'esecuzione dell'esame in farmacia permette al paziente un accesso rapido e immediato con
                            minimi tempi di attessa. L'esame ha la medesima validità dello stesso svolto presso altra
                            struttura sanitaria.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <LocalPhoneIcon fontSize="large" />
                        <Typography variant="h5" gutterBottom component="h3">
                            Prenota
                        </Typography>
                        <Typography>
                            Chiama il numero{" "}
                            <Link type="external" href="tel:+390457150797" title="Prenota holter cardiaco via telefono">
                                0457150797
                            </Link>{" "}
                            oppure manda un messaggio Whatsapp al numero{" "}
                            <Link
                                type="external"
                                href="https://wa.me/+393272317444"
                                title="Prenota holter cardiaco via whatsapp"
                            >
                                3272317444
                            </Link>
                            .
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DriveEtaIcon fontSize="large" />
                        <Typography variant="h5" gutterBottom component="h3">
                            Vieni in farmacia
                        </Typography>
                        <Typography>
                            Sarai accolto da un farmacista certificato che installerà il dispositivo (un piccolo
                            registratore del battito cardiaco) e ti darà tutte le informazioni per un corretto
                            svolgimento dell'esame.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AccessTimeIcon fontSize="large" />
                        <Typography variant="h5" gutterBottom component="h3">
                            Torni dopo 24-48h
                        </Typography>
                        <Typography>
                            Una volta rimosso il dispositivo, i dati saranno inviati al medico cardiologo per la
                            refertazione dell'esame che riceverai entro 5 giorni lavorativi.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom component="h3" color="primary">
                            Risultati
                        </Typography>
                        <Typography>
                            Una volta eseguito l'esame riceverai il referto entro <strong>5 giorni lavorativi</strong>.
                            L'esame è certificato e refertato da un <strong>medico cardiologo</strong>.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Typography variant="h4" gutterBottom component="h3" color="primary">
                            Domande frequenti
                        </Typography>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>
                                    <strong>Posso rimuovere il dispositivo durante l'esame?</strong>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    No, il dispositivo dovrà essere indossato per tutta la durata dell'esame. Si
                                    consiglia pertanto di presentarsi all'appuntamento lavati e con indumenti puliti.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>
                                    <strong>È necessario depilare il petto prima dell'esame?</strong>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Si, per garantire una corretta adesione degli elettrodi applicati sul torace.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography>
                                    <strong>Durante l'esame quali attività possono essere svolte?</strong>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Conduci la tua vita nella quotidianità evitando attività fisica intensa.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel4a-content"
                                id="panel4a-header"
                            >
                                <Typography>
                                    <strong>È un esame invasivo?</strong>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    No, il dispositivo rileva l'attività elettrica del cuore senza arrecare alcun
                                    fastidio.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Section>

            <Contatti />

            <AreeServiziCard />
        </Page>
    );
};

export default HolterCardiaco;
